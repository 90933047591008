import { linkedObjectModuleFragment, moduleFragment, moduleRecursiveFragment } from '#fragments/modules';
import { gql } from '@apollo/client/core';

export const MODULES_BY_PK_QUERY = gql`
  query ModulesByPk($id: Int!) {
    modules_by_pk(id: $id) {
      ...ModuleRecursiveFragment
    }
  }
  ${moduleRecursiveFragment}
`;

export const MODULES_QUERY_LIST = gql`
  ${moduleFragment}
  query modulesQuery($nameSearch: String, $where: modules_bool_exp!, $orderBy: [modules_order_by!], $limit: Int!, $offset: Int!) {
    modules(
      where: $where,
      order_by: $orderBy,
      limit: $limit,
      offset: $offset,
    ) {
      ...ModuleFragment
    }
  }
`;

export const MODULES_QUERY_LIST_WITH_NAME = gql`
  ${moduleFragment}
  query modulesQuery($nameSearch: String, $where: modules_bool_exp!, $orderBy: [modules_order_by!], $limit: Int!, $offset: Int!) {
    search_modules_by_name(
      args: { name_search: $nameSearch },
      where: $where,
      order_by: $orderBy,
      limit: $limit,
      offset: $offset,
    ) {
      ...ModuleFragment
    }
  }
`;

export const MODULE_UPDATE = gql`
  ${moduleRecursiveFragment}
  mutation UpdateModule($object: modules_set_input!, $id: Int!) {
    update_modules_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...ModuleRecursiveFragment
    }
  }
`;

export const MODULE_INSERT = gql`
  ${moduleRecursiveFragment}
  mutation InsertModule($object: modules_insert_input!) {
    insert_modules_one(object: $object) {
      ...ModuleRecursiveFragment
    }
  }
`;

export const MODULE_DUPLICATE_ALL = gql`
  ${moduleRecursiveFragment}
  mutation DuplicateAllModule($objects: [modules_insert_input!]!) {
    insert_modules(objects: $objects) {
      returning {
        ...ModuleRecursiveFragment
  }}
}`;

export const MODULE_DELETE = gql`
  mutation DeleteModule($id: Int!) {
    update_modules_by_pk(pk_columns: { id: $id }, _set: { _deleted_at: "now()" }) {
      id
      _deleted_at
    }
  }
`;

export const GET_MODULE_USAGE_IN_MODULE = gql`
  query GetModuleUsageInModule($id: Int!) {
    linked_module_module_aggregate(where: {linked_module_id: { _eq: $id }, parent_module: { is_standard: {_eq: true}, _deleted_at: {_is_null: true} }, is_deleted_at_version: {_eq: 0}}, order_by: {_updated_at: desc}) {
    aggregate {
      count
    }
    nodes {
      parent_module {
        id
        latest_module_version(where: {is_latest: {_eq: true}}) {
          version
          name
          is_latest
          id
        }
      }
      _created_at
    }
  }
  }
`;

export const GET_MODULE_USAGE_IN_QUOTE = gql`
  query GetModuleUsageInQuote($id: Int!) {
    standardModule: quote_item_module_aggregate(where: {module_id: {_eq: $id}, quote_section: {quote: {status: {_neq: "archived"}}}}, order_by: {_updated_at: desc}) {
      aggregate {
        count
      }
      nodes {
        module {
          id
          is_standard
          latest_module_version(where: {is_latest: {_eq: true}}) {
            version
            name
            is_latest
            id
          }
        }
        quote_section {
          quote {
            id
            name
            status
            reference
            customer {
              name
            }
          }
        }
        _created_at
      }
    }
    nonStandardModule: quote_item_module_aggregate(
      where: {
        module: {from_module_id: {_eq: $id}},
        quote_section: {quote: {status: {_neq: "archived"}}}
      },
      order_by: {_updated_at: desc}
    ) {
      aggregate {
        count
      }
      nodes {
        module {
          id
          is_standard
          latest_module_version(where: {is_latest: {_eq: true}}) {
            version
            name
            is_latest
            id
          }
        }
        quote_section {
          quote {
            id
            name
            status
            reference
            customer {
              name
            }
          }
        }
        _created_at
      }
    }
  }
`;

export const MODULE_VERSION_UPDATE = gql`
  mutation UpdateModuleVersion($object: module_versions_insert_input!, $id: Int!) {
    update_module_versions(
      where: { _and: { module_id: { _eq: $id }, is_latest: { _eq: true } } }
      _set: { is_latest: false }
    ) {
      affected_rows
    }

    insert_module_versions_one(object: $object) {
      id
      name
      version
      is_latest
      module_id
      attachments
      description
      _created_at
      _created_by
      _updated_at
      _updated_by
    }
  }
`;

export const MODULE_OBJECT_LINK_INSERT = gql`
  ${linkedObjectModuleFragment}
  mutation InsertObjectModuleLink($object: linked_object_module_insert_input!) {
    insert_linked_object_module_one(object: $object) {
      ...LinkedObjectModuleFragment
    }
  }
`;

export const MODULE_OBJECT_LINK_INSERT_BULK = gql`
  ${linkedObjectModuleFragment}
  mutation InsertObjectModuleLinkBulk($objects: [linked_object_module_insert_input!]!) {
    insert_linked_object_module(objects: $objects) {
      ...LinkedObjectModuleFragment
    }
  }
`;

export const MODULE_MODULE_LINK_INSERT = gql`
  ${moduleRecursiveFragment}
  mutation InsertModuleModuleLink($object: linked_module_module_insert_input!) {
    insert_linked_module_module_one(object: $object) {
      id
      data
      value
      margin
      _updated_by
      _updated_at
      _created_by
      _created_at
      module_id
      linked_module_id
      module {
        ...ModuleRecursiveFragment
      }
    }
  }
`;

export const MODULE_MODULE_LINK_UPDATE = gql`
  ${moduleRecursiveFragment}
  mutation UpdateModuleModuleLink($id: Int!, $object: linked_module_module_set_input!) {
    update_linked_module_module_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      data
      value
      margin
      _updated_by
      _updated_at
      _created_by
      _created_at
      module_id
      linked_module_id
      module{
        ...ModuleRecursiveFragment
      }
    }
  }
`;

export const MODULE_OBJECT_LINK_UPDATE = gql`
  ${moduleRecursiveFragment}
  mutation UpdateObjectModuleLink($id: Int!, $object: linked_object_module_set_input!) {
    update_linked_object_module_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      data
      value
      margin
      _updated_by
      _updated_at
      _created_by
      _created_at
      database_object_id
      database_object {
        database_id
        __typename
        _created_at
        _created_by
        _updated_at
        _updated_by
        _deleted_at
        id
        database {
          id
          latest_database_version(where: { is_latest: { _eq: true } }) {
            attributes
            id
            name
            formula
          }
        }
        latest_database_object_version(where: { is_latest: { _eq: true } }) {
          id
          name
          data
          version
          margin
          database_object_id
          is_latest
          _created_at
          _created_by
          _updated_at
          _updated_at
        }
      }
      module_id
      module {
        ...ModuleRecursiveFragment
      }
    }
  }
`;

export const MODULE_OBJECT_LINK_DELETE = gql`
  mutation DeleteObjectModuleLink($id: Int!, $module_version: float8!) {
    update_linked_object_module(where: { id: { _eq: $id } }, _set: { is_deleted_at_version: $module_version }) {
      affected_rows
    }
  }
`;

export const MODULE_MODULE_LINK_DELETE = gql`
  mutation DeleteModuleModuleLink($id: Int!, $module_version: float8!) {
    update_linked_module_module(where: { id: { _eq: $id } }, _set: { is_deleted_at_version: $module_version }) {
      affected_rows
    }
  }
`;

export const MODULE_TAGS_INSERT = gql`
  mutation InsertModuleTags($objects: [module_tags_insert_input!]!) {
    insert_module_tags(objects: $objects) {
      affected_rows
    }
  }
`;

export const MODULE_TAGS_DESTROY = gql`
  mutation DeleteModuleTags($moduleId: Int!, $tagIds: [Int!]!) {
    delete_module_tags(
      where: {
        module_id: { _eq: $moduleId },
        tag_id: { _in: $tagIds }
      }
    ) {
      affected_rows
    }
  }
`;

export const MODULE_SEARCH_WITH_NAME = gql`
  ${moduleRecursiveFragment}
  query modulesSearchQuery($nameSearch: String!, $searchOptions: modules_bool_exp!) {
  search_modules_by_name(args: { name_search: $nameSearch }, where: $searchOptions, limit: 15) {
      ...ModuleRecursiveFragment
  }
}
`;

export const MODULE_SEARCH_NO_NAME = gql`
  ${moduleRecursiveFragment}
  query modulesSearchQuery($searchOptions: modules_bool_exp!) {
  modules(where: $searchOptions, limit: 15) {
      ...ModuleRecursiveFragment
  }
}
`;

export const MODULE_OBJECT_LINK_UPDATE_BULK = gql`
  ${moduleRecursiveFragment}
  mutation UpdateObjectModuleLinkBulk($updates: [linked_object_module_updates!]!) {
    update_linked_object_module_many(updates: $updates) {
      returning {
        id
        data
        value
        margin
        _updated_by
        _updated_at
        _created_by
        _created_at
        database_object_id
        database_object {
          database_id
          __typename
          _created_at
          _created_by
          _updated_at
          _updated_by
          _deleted_at
          id
          database {
            id
            latest_database_version(where: { is_latest: { _eq: true } }) {
              attributes
              id
              name
              formula
            }
          }
          latest_database_object_version(where: { is_latest: { _eq: true } }) {
            id
            name
            data
            version
            margin
            database_object_id
            is_latest
            _created_at
            _created_by
            _updated_at
            _updated_at
          }
        }
        module_id
        module {
          ...ModuleRecursiveFragment
        }
      }
    }
  }
`;

export const MODULE_PARENT_SIMPLE_QUERY = gql`
  query ModuleParentsSimpleQuery($id: Int!) {
  modules_by_pk(id: $id) {
    id
      parent_linked_module_modules(where: { parent_module: { _deleted_at: { _is_null: true }}}) {
      id
        parent_module {
        id
          parent_linked_module_modules(where: { parent_module: { _deleted_at: { _is_null: true }}}) {
          id
            parent_module {
            id
              parent_linked_module_modules(where: { parent_module: { _deleted_at: { _is_null: true }}}) {
              id
                parent_module {
                id
                  parent_linked_module_modules(where: { parent_module: { _deleted_at: { _is_null: true }}}) {
                  id
                    parent_module {
                    id
                      parent_linked_module_modules(where: { parent_module: { _deleted_at: { _is_null: true }}}) {
                      id
                        parent_module {
                        id
                          parent_linked_module_modules(where: { parent_module: { _deleted_at: { _is_null: true }}}) {
                          id
                            parent_module {
                            id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
