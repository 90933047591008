import { gql } from '@apollo/client/core';

export const UPSERT_SIMPLIFIED_MODULE = gql`
  mutation UpsertSimplifiedModule(
    $simplifiedModuleId: bigint!
    $simplifiedModule: ai_simplified_modules_insert_input!
  ) {
    delete_ai_simplified_module_items(where: { simplified_module: { module_id: { _eq: $simplifiedModuleId } } }) {
      affected_rows
    }
    insert_ai_simplified_modules_one(
      object: $simplifiedModule,
      on_conflict: {
        constraint: simplified_modules_module_id_key,
        update_columns: [name, description, industrial_cost, is_standard]
      }
    ) {
      id
    }
  }
`;
