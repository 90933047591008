import type { User } from 'firebase/auth';
import * as amplitude from '@amplitude/analytics-browser';
import { UserRoleEnum } from '@relief/types';
import * as Sentry from '@sentry/vue';
import { useQueryClient } from '@tanstack/vue-query';
import { Crisp } from 'crisp-sdk-web';
import { onAuthStateChanged } from 'firebase/auth';
import { clone } from 'lodash-es';
import { defineStore } from 'pinia';
import apolloClient from '~/composables/graphql';
import { useGetCurrentUser, useGetUsers } from '~/queries/users';
import { fb_auth } from '~/services/firebase';

export const usersStore = defineStore('users', () => {
  const userId = ref(fb_auth.currentUser?.uid);
  const setUserId = (id: string | undefined) => {
    userId.value = id;
  };
  const firebaseUserFetched = ref(false);
  const firebaseUser = ref<User | null>(fb_auth.currentUser);
  const clientId = ref<number | undefined>();
  const unsubscribe = onAuthStateChanged(fb_auth, (user) => {
    firebaseUserFetched.value = true;
    setUserId(user?.uid);
    firebaseUser.value = user;
    user?.getIdTokenResult().then((token) => {
      clientId.value = Number(
        token.claims?.['https://hasura.io/jwt/claims']?.['x-hasura-client-id'] || token.claims?.['https://hasura.io/jwt/claims']?.['X-Hasura-Client-Id'] || 0,
      ) || undefined;
    });
  }, (error) => {
    console.error('Error on auth state change', error);
  });

  const { data: currentUserData, isLoading: isLoadingCurrentUser } = useGetCurrentUser(computed(() => !!userId.value));
  const { cloned: currentUser } = useCloned(currentUserData, { clone });
  const isAdmin = computed(() => currentUser.value?.role === UserRoleEnum.Admin);

  watch(currentUser, () => {
    Crisp.user.setEmail(String(currentUser.value?.email));
    Crisp.user.setNickname(`${currentUser.value?.first_name} ${currentUser.value?.last_name}`);
    Crisp.session.setData({
      client_id: currentUser.value?.client_id,
    });
    Sentry.setUser({
      email: currentUser.value?.email,
      id: currentUser.value?.id,
    });
    if (currentUser.value) {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('email', currentUser.value.email);
      identifyEvent.set('user_id', currentUser.value.id);
      identifyEvent.set('name', `${currentUser.value?.first_name} ${currentUser.value?.last_name}`);
      amplitude.identify(identifyEvent);
    }
  });

  const { data: usersData, isLoading: isLoadingUsers } = useGetUsers(computed(() => currentUser.value?.client_id), computed(() => !!userId.value && !!currentUser.value?.client_id));
  const { cloned: users } = useCloned(usersData, { clone });
  function getUserById(id: string) {
    return users.value?.find(row => row.id === id);
  };

  function filterUsers(ids: string[]) {
    return users.value?.filter((u) => {
      return ids?.length && ids?.includes(u?.id);
    }) || [];
  };

  const queryClient = useQueryClient();
  const router = useRouter();
  async function logout() {
    unsubscribe();
    await fb_auth.signOut();
    await apolloClient.clearStore();
    queryClient.clear();
    router.push('/aut/login');
    Crisp.session.reset(false);
    Crisp.chat.hide();
  }

  return {
    firebaseUserFetched,
    firebaseUser,
    clientId,
    setUserId,
    currentUser,
    isLoadingCurrentUser,
    me: currentUser,
    isAdmin,
    users,
    isLoadingUsers,
    getUserById,
    filterUsers,
    logout,
  };
});
