import { gql } from '@apollo/client/core';

export const simplifiedQuoteItemFragment = gql`
  fragment SimplifiedQuoteItemFragment on ai_simplified_quote_items {
    id
    simplified_quote_id
    section_name
    section_description
    section_order
    item_name
    item_type
    linked_object_id
    linked_object_name
    description
    quantity
    industrial_cost
    commercial_margin
    sell_price
    _created_at
    _updated_at
  }
`;

export const simplifiedQuoteFragment = gql`
  ${simplifiedQuoteItemFragment}
  fragment SimplifiedQuoteFragment on ai_simplified_quotes {
    id
    client_id
    quote_id
    name
    customer
    internal_hypothesis
    date_of_issue
    taxes_value
    discounts_value
    total_sell_price
    tags
    _created_at
    _updated_at
    items {
      ...SimplifiedQuoteItemFragment
    }
  }
`;
