import { jobFragment } from '#fragments/jobs';
import { gql } from '@apollo/client/core';

export const JOB_QUERY = gql`
  ${jobFragment}
  query JobQuery($id: bigint!) {
    jobs_by_pk(id: $id) {
      ...JobFragment
    }
  }
`;

export const JOB_QUERY_LIST_WITH_FILTERS = gql`
  ${jobFragment}
  query JobQueryList($filters: jobs_bool_exp) {
    jobs(where: $filters) {
      ...JobFragment
    }
  }
`;

export const JOB_CREATE = gql`
  ${jobFragment}
  mutation CreateJob($object: jobs_insert_input!) {
    insert_jobs_one(object: $object) {
      ...JobFragment
    }
  }
`;

export const JOB_UPDATE = gql`
  ${jobFragment}
  mutation UpdateJob($object: jobs_set_input!, $id: bigint!) {
    update_jobs_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...JobFragment
    }
  }
`;
