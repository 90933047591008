import { businessData } from '#fragments/businesses';
import { gql } from '@apollo/client/core';

export const BUSINESS_QUERY_LIST_WITH_NAME = gql`
  ${businessData}
  query BusinessesList($limit: Int!, $offset: Int!, $orderBy: [businesses_order_by!], $where: businesses_bool_exp, $nameSearch: String) {
    search_businesses_by_name(args: { name_search: $nameSearch }, limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
      ...BusinessFragment
      quote {
        name
        id
        reference
        description
        date
        customer {
          name
          id
        }
        assignee_id
        attachments
        status
      }
    }
  }
`;

export const BUSINESS_QUERY_LIST = gql`
  ${businessData}
  query BusinessesList($limit: Int!, $offset: Int!, $orderBy: [businesses_order_by!], $where: businesses_bool_exp, $nameSearch: String) {
    businesses(limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
      ...BusinessFragment
      quote {
        name
        id
        reference
        description
        date
        customer {
          name
          id
        }
        assignee_id
        attachments
        status
      }
    }
  }
`;

export const BUSINESS_QUERY_LIST_BY_CLIENT_ID = gql`
  ${businessData}
  query BusinessQuery($clientId: Int!) {
    businesses(where: {quote: {customer: {client_id: {_eq: $clientId}}}}) {
      ...BusinessFragment
      quote {
        name
        id
        reference
        description
        date
        customer {
          name
          id
        }
        assignee_id
        attachments
      }
    }
  }
`;
export const BUSINESS_QUERY = gql`
  ${businessData}
  query BusinessQuery($id: Int!) {
    businesses_by_pk(id: $id) {
      ...BusinessFragment
    }
  }
`;

export const BUSINESS_UPDATE = gql`
  ${businessData}
  mutation UpdateBusiness($object: businesses_set_input!, $id: Int!) {
    update_businesses_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...BusinessFragment
    }
  }
`;

export const BUSINESS_INSERT = gql`
  ${businessData}
  mutation InsertBusiness($object: businesses_insert_input!) {
    insert_businesses_one(object: $object) {
      ...BusinessFragment
    }
  }
`;

export const BUSINESS_STATUS_AGGREGATE = gql`
query StatusAggregation {
  businesses_aggregate(distinct_on: status) {
    nodes {
      status
    }
  }
}
`;
