import { tagFragment } from '#fragments/tag';
import { gql } from '@apollo/client/core';

export const TAGS_QUERY_LIST = gql`
  query TagsQuery($type: String) {
    tags(where: { type: { _eq: $type } }) {
      ...TagFragment
    }
  }
  ${tagFragment}
`;

export const BULK_TAG_INSERT = gql`
  mutation BulkTagInsert($objects: [tags_insert_input!]!) {
    insert_tags(
      objects: $objects,
    ) {
      returning {
        ...TagFragment
      }
      affected_rows
    }
  }
  ${tagFragment}
`;
