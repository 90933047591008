import { quoteVersionFragment } from '#fragments/quoteVersion';
import { gql } from '@apollo/client/core';

export const businessData = gql`
${quoteVersionFragment}
fragment BusinessFragment on businesses {
  id
  quote_id
  quote_version_id
  real_cost
  inputs_json
  status
  reference
  _created_at
  _created_by
  _updated_at
  _updated_by
  quote {
    name
    reference
  }
  quote_version {
    ...QuoteVersionFragment
  }
}
`;
