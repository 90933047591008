import { gql } from '@apollo/client/core';

export const moduleViewFragment = gql`
  fragment ModuleViewFragment on module_views {
    id
    client_id
    name
    search
    tags
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;
