import { gql } from '@apollo/client/core';
import { databaseObjectFragment } from './databaseObject';
import { moduleInsightsFragment } from './insight';
import { moduleSubcontractingLinkFragment } from './subcontracting';
import { tagFragment } from './tag';

export const moduleVersionFragment = gql`
  fragment ModuleVersionFragment on module_versions {
    id
    name
    version
    is_latest
    module_id
    attachments
    description
    reference
    _updated_at
    _updated_by
    _created_at
    _created_by
  }
`;

export const moduleDataFragment = gql`
fragment ModuleDataFragment on modules {
  id
  client_id
  is_standard
  from_module_id
  module_tags {
    tag {
      ...TagFragment
    }
  }
  _created_at
  _created_by
  _updated_at
  _updated_by
  _deleted_at
}
${tagFragment}
`;

export const moduleFragment = gql`
${moduleVersionFragment}
${moduleDataFragment}
${moduleInsightsFragment}
${moduleSubcontractingLinkFragment}
fragment ModuleFragment on modules {
  ...ModuleDataFragment
  latest_module_version(where: { is_latest: { _eq: true } }) {
    ...ModuleVersionFragment
  }
  insights(order_by: { _created_at: desc }, where: { status: { _is_null: true } }){
    ...ModuleInsightsFragment
  }
  module_subcontracting_link(where: { _deleted_at: { _is_null: true } }) {
    ...ModuleSubcontractingLinkFragment
  }
}
`;

export const linkedObjectModuleDataFragment = gql`
  fragment LinkedObjectModuleDataFragment on linked_object_module {
    id
    data
    value
    margin
    _updated_by
    _updated_at
    _created_by
    _created_at
    is_deleted_at_version
    module_id
  }
`;

export const linkedObjectModuleFragment = gql`
  ${linkedObjectModuleDataFragment}
  ${databaseObjectFragment}
  ${moduleFragment}
  fragment LinkedObjectModuleFragment on linked_object_module {
    ...LinkedObjectModuleDataFragment
    database_object_id
    database_object {
      ...DatabaseObjectFragment
    }
    module_id
    module {
      ...ModuleFragment
    }
  }
`;

export const linkedModuleModuleDataFragment = gql`
  fragment LinkedModuleModuleDataFragment on linked_module_module {
    id
    value
    margin
    data
    module_id
    linked_module_id
    _updated_by
    _updated_at
    _created_by
    _created_at
  }
`;

export const moduleRecursiveFragment = gql`
  ${moduleFragment}
  ${linkedObjectModuleFragment}
  ${linkedModuleModuleDataFragment}
  fragment ModuleRecursiveFragment on modules {
    ...ModuleFragment
    linked_object_modules(
      where: { is_deleted_at_version: { _eq: 0 }, database_object: { _deleted_at: { _is_null: true } } }
    ) {
      ...LinkedObjectModuleFragment
    }
    children_linked_module_modules(where: { is_deleted_at_version: { _eq: 0 }, module: { _deleted_at: { _is_null: true } } }) {
      ...LinkedModuleModuleDataFragment
      linked_module_id
      module {
        ...ModuleFragment
        linked_object_modules(
          where: { is_deleted_at_version: { _eq: 0 }, database_object: { _deleted_at: { _is_null: true } } }
        ) {
          ...LinkedObjectModuleFragment
        }
        children_linked_module_modules(where: { is_deleted_at_version: { _eq: 0 }, module: { _deleted_at: { _is_null: true } } }) {
          ...LinkedModuleModuleDataFragment
          module {
            ...ModuleFragment
            linked_object_modules(
              where: { is_deleted_at_version: { _eq: 0 }, database_object: { _deleted_at: { _is_null: true } } }
            ) {
              ...LinkedObjectModuleFragment
            }
            children_linked_module_modules(where: { is_deleted_at_version: { _eq: 0 }, module: { _deleted_at: { _is_null: true } } }) {
              ...LinkedModuleModuleDataFragment
              module {
                ...ModuleFragment
                linked_object_modules(
                  where: { is_deleted_at_version: { _eq: 0 }, database_object: { _deleted_at: { _is_null: true } } }
                ) {
                  ...LinkedObjectModuleFragment
                }
                children_linked_module_modules(where: { is_deleted_at_version: { _eq: 0 }, module: { _deleted_at: { _is_null: true } } }) {
                  ...LinkedModuleModuleDataFragment
                  module {
                    ...ModuleFragment
                    linked_object_modules(
                      where: { is_deleted_at_version: { _eq: 0 }, database_object: { _deleted_at: { _is_null: true } } }
                    ) {
                      ...LinkedObjectModuleFragment
                    }
                    children_linked_module_modules(where: { is_deleted_at_version: { _eq: 0 }, module: { _deleted_at: { _is_null: true } } }) {
                      ...LinkedModuleModuleDataFragment
                      module {
                        ...ModuleFragment
                        linked_object_modules(
                          where: { is_deleted_at_version: { _eq: 0 }, database_object: { _deleted_at: { _is_null: true } } }
                        ) {
                          ...LinkedObjectModuleFragment
                        }
                        children_linked_module_modules(where: { is_deleted_at_version: { _eq: 0 }, module: { _deleted_at: { _is_null: true } } }) {
                          ...LinkedModuleModuleDataFragment
                          module {
                            ...ModuleFragment
                            linked_object_modules(
                              where: { is_deleted_at_version: { _eq: 0 }, database_object: { _deleted_at: { _is_null: true } } }
                            ) {
                              ...LinkedObjectModuleFragment
                            }
                            children_linked_module_modules(where: { is_deleted_at_version: { _eq: 0 }, module: { _deleted_at: { _is_null: true } } }) {
                              ...LinkedModuleModuleDataFragment
                              module {
                                ...ModuleFragment
                                linked_object_modules(
                                  where: { is_deleted_at_version: { _eq: 0 }, database_object: { _deleted_at: { _is_null: true } } }
                                ) {
                                  ...LinkedObjectModuleFragment
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
