import { aiAgentDataSourceFragment } from '#fragments/ai_agents';
import { gql } from '@apollo/client/core';

export const AI_AGENT_DATA_SOURCES_INSERT = gql`
  ${aiAgentDataSourceFragment}
  mutation InsertAiAgentDataSource($object: ai_agent_data_sources_insert_input!) {
    insert_ai_agent_data_sources_one(
      object: $object,
    ) {
      ...AiAgentDataSourceFragment
    }
  }
`;

export const AI_AGENT_DATA_SOURCES_UPDATE = gql`
  ${aiAgentDataSourceFragment}
  mutation UpdateAiAgentDataSources($id: bigint!, $set: ai_agent_data_sources_set_input) {
    update_ai_agent_data_sources_by_pk(
      pk_columns: {id: $id},
      _set: $set,
    ) {
      ...AiAgentDataSourceFragment
    }
  }
`;

export const AI_AGENT_DATA_SOURCES_DELETE = gql`
  ${aiAgentDataSourceFragment}
  mutation DeleteAiAgentDataSources($id: bigint!) {
    delete_ai_agent_data_sources_by_pk(
      id: $id,
    ) {
      ...AiAgentDataSourceFragment
    }
  }
`;
