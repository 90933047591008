import { gql } from '@apollo/client/core';
import { tagFragment } from './tag';

export const quoteTagFragment = gql`
  ${tagFragment}
  fragment QuoteTagFragment on quote_tags {
    quote_id
    tag_id
    tag {
      ...TagFragment
    }
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;
