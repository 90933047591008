import { customerCompleteFragment } from '#fragments/customers';
import { gql } from '@apollo/client/core';

export const CUSTOMERS_QUERY_LIST_ALL = gql`
  ${customerCompleteFragment}
  query CustomersQueryListAll {
    customers {
      ...CustomerCompleteFragment
    }
  }
`;

export const CUSTOMERS_QUERY_LIST = gql`
  ${customerCompleteFragment}
  query CustomersQueryList($limit: Int!, $offset: Int!, $orderBy: [customers_order_by!], $nameSearch: Stringn $where: customers_bool_exp) {
    customers(limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
      ...CustomerCompleteFragment
    }
  }
`;

export const CUSTOMERS_QUERY_LIST_WITH_NAME = gql`
  ${customerCompleteFragment}
  query CustomersQueryListWithName($limit: Int!, $offset: Int!, $orderBy: [customers_order_by!], $nameSearch: String) {
    search_customers_by_name(args: { name_search: $nameSearch }, limit: $limit, offset: $offset, order_by: $orderBy) {
      ...CustomerCompleteFragment
    }
  }
`;

export const CUSTOMERS_QUERY_WITH_NAME = gql`
  ${customerCompleteFragment}
  query CustomersQueryWithName($nameSearch: String) {
    search_customers_by_name(args: { name_search: $nameSearch }) {
      ...CustomerCompleteFragment
    }
  }
`;

export const CUSTOMERS_QUERY = gql`
  ${customerCompleteFragment}
  query CustomerQuery($id: Int!) {
    customers_by_pk(id: $id) {
      ...CustomerCompleteFragment
    }
  }
`;

export const CUSTOMER_UPDATE = gql`
  ${customerCompleteFragment}
  mutation UpdateCustomer($object: customers_set_input!, $id: Int!) {
    update_customers_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...CustomerCompleteFragment
    }
  }
`;

export const CUSTOMER_INSERT = gql`
  ${customerCompleteFragment}
  mutation InsertCustomer($object: customers_insert_input!) {
    insert_customers_one(object: $object) {
      ...CustomerCompleteFragment
    }
  }
`;

export const CUSTOMER_DELETE = gql`
  mutation DeleteCustomer($id: Int!) {
    delete_customers(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const ADD_TAGS_TO_CUSTOMER = gql`
  mutation AddTagsToCustomer($objects: [customer_tags_insert_input!]!) {
    insert_customer_tags(
      objects: $objects,
    ) {
      returning {
        tag {
          id
        }
      }
    }
  }
`;

export const REMOVE_TAGS_FROM_CUSTOMER = gql`
  mutation RemoveTagsFromCustomer($customer_id: Int!, $tag_ids: [Int!]!) {
    delete_customer_tags(
      where: {
        customer_id: { _eq: $customer_id },
        tag_id: { _in: $tag_ids }
      }
    ) {
      affected_rows
    }
  }
`;
