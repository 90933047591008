import { gql } from '@apollo/client/core';

export const simplifiedModuleItemFragment = gql`
  fragment SimplifiedModuleItemFragment on ai_simplified_module_items {
    id
    simplified_module_id
    item_name
    item_type
    linked_object_id
    linked_object_name
    description
    quantity
    industrial_cost
    commercial_margin
    sell_price
  }
`;

export const simplifiedModuleFragment = gql`
${simplifiedModuleItemFragment}
  fragment SimplifiedModuleFragment on ai_simplified_modules {
    id
    name
    description
    reference
    is_standard
    from_module_id
    version
    industrial_cost
    commercial_margin
    sell_price
    _updated_at
    items {
      ...SimplifiedModuleItemFragment
    }
  }
`;
