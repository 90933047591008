import { gql } from '@apollo/client/core';

export const simplifiedDatabaseFragment = gql`
  fragment SimplifiedDatabaseFragment on ai_simplified_databases {
    id
    name
    description
    database_id
    client_id
    attributes
    version
    formula
    default_margin
    _created_at
    _updated_at
  }
`;
