import { moduleViewFragment } from '#fragments/moduleViews';
import { gql } from '@apollo/client/core';

export const MODULE_VIEWS_QUERY = gql`
  ${moduleViewFragment}
  query ModuleViewsQuery {
    module_views {
      ...ModuleViewFragment
    }
  }
`;

export const MODULE_VIEW_INSERT = gql`
  ${moduleViewFragment}
  mutation InsertModuleView($object: module_views_insert_input!) {
    insert_module_views_one(object: $object) {
      ...ModuleViewFragment
    }
  }
`;

export const MODULE_VIEW_UPDATE = gql`
  ${moduleViewFragment}
  mutation UpdateModuleView($id: Int!, $object: module_views_set_input!) {
    update_module_views_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...ModuleViewFragment
    }
  }
`;

export const MODULE_VIEW_DELETE = gql`
  ${moduleViewFragment}
  mutation DeleteModuleView($id: Int!) {
    delete_module_views_by_pk(id: $id) {
      ...ModuleViewFragment
    }
  }
`;
