import { databaseDataFragment, databaseVersionDataFragment } from '#fragments/database';
import { databaseObjectDataFragment, databaseObjectFragment, databaseObjectVersionFragment } from '#fragments/databaseObject';
import { databaseKpiDataFragment, databaseKpiVersionFragment } from '#fragments/kpis';
import { linkedObjectModuleDataFragment, moduleDataFragment, moduleRecursiveFragment, moduleVersionFragment } from '#fragments/modules';
import { quoteFeeFragment } from '#fragments/quoteFees';
import { quoteDataFragment } from '#fragments/quotes';
import { quoteItemDatabaseObjectDataFragment, quoteItemModuleDataFragment, quoteItemTaxDataFragment, quoteSectionDataFragment } from '#fragments/quoteSection';
import { quoteVersionFragment } from '#fragments/quoteVersion';
import { referenceDataFragment, referenceVersionFragment } from '#fragments/references';
import { subcontractingDataFragment, subcontractingVersionFragment } from '#fragments/subcontracting';
import { taxFragment, taxVersionFragment } from '#fragments/taxes';
import { gql } from '@apollo/client/core';

export const QUOTE_VERSION_INSERT = gql`
  ${quoteVersionFragment}
  mutation CreateQuoteVersion($object: quote_versions_insert_input!, $quote_id: Int!) {
    update_quote_versions(
      where: { _and: { quote_id: { _eq: $quote_id }, is_latest: { _eq: true } } }
      _set: { is_latest: false }
    ) {
      affected_rows
    }

    insert_quote_versions_one(object: $object) {
      ...QuoteVersionFragment
    }
  }
`;

export const QUOTE_VERSION_UPDATE = gql`
${quoteVersionFragment}
  mutation UpdateQuoteVersion($object: quote_versions_set_input!, $quote_id: Int!) {
    update_quote_versions(
      where: { _and: { quote_id: { _eq: $quote_id }, is_latest: { _eq: true } } }
      _set: $object
    ) {
      returning {
        ...QuoteVersionFragment
      }
    }
  }
`;

export const QUOTE_VERSIONS_QUERY = gql`
${quoteVersionFragment}
  query GetQuoteVersionsById($quoteId: Int!) {
    quote_versions(where: { quote : { id: { _eq: $quoteId } } }) {
      ...QuoteVersionFragment
    }
  }
`;

export const QUOTE_OBJECTS_QUERY = gql`
  ${quoteDataFragment}
  ${quoteSectionDataFragment}
  ${quoteItemModuleDataFragment}
  ${moduleVersionFragment}
  ${moduleDataFragment}
  ${linkedObjectModuleDataFragment}
  ${quoteItemDatabaseObjectDataFragment}
  ${databaseObjectDataFragment}
  ${databaseObjectVersionFragment}
  ${databaseDataFragment}
  ${databaseVersionDataFragment}
  ${subcontractingDataFragment}
  ${subcontractingVersionFragment}
  ${quoteItemTaxDataFragment}
  ${quoteFeeFragment}
  ${taxFragment}
  ${taxVersionFragment}
  ${referenceDataFragment}
  ${referenceVersionFragment}
  ${databaseKpiDataFragment}
  ${databaseKpiVersionFragment}
  query QuoteObjects(
    $quoteId: Int!
    $sectionIds: [Int!]!
    $quoteFeeIds: [Int!]!

    $databaseIds: [Int!]!
    $databaseVersionIds: [Int!]!
    $referenceVersionIds: [Int!]!
    $referenceIds: [Int!]!

    $quoteItemDatabaseObjectIds: [Int!]!
    $databaseObjectIds: [Int!]!
    $databaseObjectVersionIds: [Int!]!

    $quoteItemModuleIds: [Int!]!
    $moduleIds: [Int!]!
    $moduleVersionIds: [Int!]!
    $linkedModuleModuleIds: [Int!]!
    $linkedObjectModuleIds: [Int!]!

    $quoteItemSubcontractingIds: [bigint!]!
    $subcontractingIds: [bigint!]!
    $subcontractingVersionIds: [bigint!]!

    $quoteItemTaxIds: [bigint!]!
    $taxIds: [bigint!]!
    $taxVersionIds: [bigint!]!

    $databaseKpiIds: [Int]!
    $databaseKpiVersionIds: [Int!]!
  ) {
    quotes(where: { id: { _eq: $quoteId } }) {
      ...QuoteDataFragment
    }
    quote_sections(where: { id: { _in: $sectionIds } }) {
      ...QuoteSectionDataFragment
    }
    quote_fees(where: { id: { _in: $quoteFeeIds } }) {
      ...QuoteFeeFragment
    }
    databases(where: { id: { _in: $databaseIds } }) {
      ...DatabaseDataFragment
      latest_database_version(where: { id: { _in: $databaseVersionIds } }) {
        ...DatabaseVersionDataFragment
      }
    }
    quote_item_database_object(where: { id: { _in: $quoteItemDatabaseObjectIds } }) {
      ...QuoteItemDatabaseObjectDataFragment
    }
    database_objects(where: { id: { _in: $databaseObjectIds } }) {
      ...DatabaseObjectDataFragment
      latest_database_object_version(where: { id: { _in: $databaseObjectVersionIds } }) {
        ...DatabaseObjectVersionFragment
      }
    }
    quote_item_module(where: { id: { _in: $quoteItemModuleIds } }) {
      ...QuoteItemModuleDataFragment
    }
    linked_module_module(where: { id: { _in: $linkedModuleModuleIds } }) {
      id
      module_id
      linked_module_id
      value
      margin
      data
      _updated_by
      _updated_at
      _created_by
      _created_at
    }
    linked_object_module(where: { id: { _in: $linkedObjectModuleIds } }) {
      ...LinkedObjectModuleDataFragment
    }
    modules(where: { id: { _in: $moduleIds } }) {
      ...ModuleDataFragment
      latest_module_version(where: { id: { _in: $moduleVersionIds } }) {
        ...ModuleVersionFragment
      }
    }
    quote_item_subcontractings(where: { id: { _in: $quoteItemSubcontractingIds } }) {
      id
      quote_section_id
      subcontracting_id
      _created_at
      _updated_at
      _created_by
      _updated_by
      _deleted_at
    }
    subcontractings(where: { id: { _in: $subcontractingIds } }) {
      ...SubcontractingDataFragment
      subcontracting_version(where: { id: { _in: $subcontractingVersionIds } }) {
        ...SubcontractingVersionFragment
      }
    }
    quote_item_taxes(where: { id: { _in: $quoteItemTaxIds } }) {
      ...QuoteItemTaxDataFragment
    }
    taxes(where: { id: { _in: $taxIds } }) {
      ...TaxFragment
      tax_versions(where: { id: { _in: $taxVersionIds } }) {
        ...TaxVersionFragment
      }
    }
    references(where: {id: {_in: $referenceIds}}) {
      ...ReferenceDataFragment
      latest_reference_version(where: { id: { _in: $referenceVersionIds } }) {
        ...ReferenceVersionFragment
      }
    }
    database_kpis(where: { id: { _in: $databaseKpiIds } }) {
      ...DatabaseKPIDataFragment
      database_kpi_versions(where: { id: { _in: $databaseKpiVersionIds } }) {
        ...DatabaseKpiVersionFragment
      }
    }
  }
`;

export const QUOTE_LATEST_OBJECTS_QUERY = gql`
  ${databaseObjectFragment}
  ${databaseObjectVersionFragment}
  ${moduleRecursiveFragment}
  ${moduleVersionFragment}
  ${taxFragment}
  ${taxVersionFragment}
  query QuoteLatestObjects(
    $databaseObjectIds: [Int!]!
    $moduleIds: [Int!]!
    $taxIds: [bigint!]!
  ) {
    database_objects(where: { id: { _in: $databaseObjectIds } }) {
      ...DatabaseObjectFragment
      latest_database_object_version(where: { is_latest: { _eq: true } }) {
        ...DatabaseObjectVersionFragment
      }
    }
    modules(where: { id: { _in: $moduleIds } }) {
      ...ModuleRecursiveFragment
      latest_module_version(where: { is_latest: { _eq: true } }) {
        ...ModuleVersionFragment
      }
    }
    taxes(where: { id: { _in: $taxIds } }) {
      ...TaxFragment
      tax_versions(where: { is_latest: { _eq: true } }) {
        ...TaxVersionFragment
      }
    }
  }
`;
