import { gql } from '@apollo/client/core';

export const ADD_TAGS_TO_DATABASE = gql`
  mutation AddTagsToDatabase($objects: [database_tags_insert_input!]!) {
    insert_database_tags(objects: $objects) {
      affected_rows
    }
  }
`;

export const REMOVE_TAGS_FROM_DATABASE = gql`
  mutation RemoveTagsFromDatabase($databaseId: Int!, $tagIds: [Int!]!) {
    delete_database_tags(where: {database_id: {_eq: $databaseId}, tag_id: {_in: $tagIds}}) {
      affected_rows
    }
  }
`;
